/**
 * Created by Reda on 19.09.2024
 */

var PinballRollView = cc.Node.extend({
    ctor: function (options) {
        this._super();
        this.options = options || {};
        this.createRoll();
        this.createArrows();
    },

    createRoll: function () {
        var styles = cleverapps.styles.PinballRollView.roll;

        var roll = this.roll = new cleverapps.Spine(bundles.pinball.jsons.ball_json);
        roll.setAnimation(0, "animation", true);
        roll.setVisible(false);

        var rollContainer = new cc.Node();
        rollContainer.setContentSize2(styles.width, roll.height);
        rollContainer.addChild(roll);
        roll.setPositionRound(styles.ball);
        this.addChild(rollContainer);
        rollContainer.setPositionRound(styles);
    },

    createArrows: function () {
        var styles = cleverapps.styles.PinballRollView.arrows;
        var arrows = this.arrows = [];

        for (var index = 0; index < 5; index++) {
            var arrow = new cleverapps.Spine(bundles.pinball.jsons.arrow_json);
            arrows.push(arrow);
        }

        var layout = new cleverapps.Layout(arrows, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        layout.setPositionRound(styles);
        this.addChild(layout);
    },

    enable: function () {
        var styles = cleverapps.styles.PinballRollView.roll;
        this.arrows.forEach(function (arrow) {
            arrow.setVisible(true);
            arrow.setAnimation(0, "idle", true);
        });
        this.roll.stopAllActions();
        this.roll.spine.stopAllActions();
        this.roll.setVisible(true);
        this.roll.setPositionRound(styles.ball);

        this.roll.runAction(
            new cc.RepeatForever(
                new cc.Sequence(
                    new cc.MoveTo(
                        styles.rollAnimationDuration,
                        this.roll.getParent().width,
                        this.roll.getPosition().y
                    ).easing(cc.easeInOut(2)),
                    new cc.PlaySound(bundles.pinball.urls.ball_rolling_effect),
                    new cc.MoveTo(
                        styles.rollAnimationDuration,
                        0,
                        this.roll.getPosition().y
                    ).easing(cc.easeInOut(2)),
                    new cc.PlaySound(bundles.pinball.urls.ball_rolling_effect)
                )
            )
        );
        this.roll.spine.runAction(AnimationsLibrary.blinkShader(this.roll.spine, {
            brightness: 0.4,
            duration: 2
        }));
    },

    disable: function () {
        this.arrows.forEach(function (arrow) {
            arrow.setVisible(false);
        });
        if (this.roll) {
            this.roll.stopAllActions();
            this.roll.spine.stopAllActions();
            this.roll.setVisible(false);
        }
    }
});

cleverapps.styles.PinballRollView = {
    roll: {
        x: { align: "left", dx: -300 },
        y: { align: "top", dy: -70 },
        rollAnimationDuration: 2,
        width: 620,
        ball: {
            x: { align: "left", dx: 0 },
            y: { align: "top", dy: -5 }
        }
    },
    arrows: {
        x: { align: "center", dx: 2 },
        y: { align: "top", dy: -130 },
        margin: 101
    }
};