/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["pinballForce"] = {
    type: Placements.FREE_FOCUS_OTHER,

    filter: function () {
        var mission = cleverapps.missionManager.findByType(Mission.TYPE_PINBALL);
        return cleverapps.environment.isPinballScene() && mission && mission.logic.isForceAvailable();
    },

    action: function () {
        var mission = cleverapps.missionManager.findByType(Mission.TYPE_PINBALL);
        mission && mission.logic.showForce();
    }
};