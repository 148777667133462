/**
 * Created by Ivan on 19.08.2024
 */

var ExtraBarrierView = cc.Node.extend({
    ctor: function (extraBarrier) {
        this._super();

        this.extraBarrier = extraBarrier;
        this.setAnchorPoint(0.5, 0.5);

        var view = this.view = new cleverapps.Spine(bundles.pinball.jsons.extra_barrier_json);
        view.setAnimation(0, "idle", true);
        this.addChild(view);
        this.setContentSize2(view.getContentSize());
        view.setPositionRound(this.width / 2, this.height / 2);
        this.extraBarrier.updateRadius(Math.max(view.width, view.height) / 2);

        var amount = this.amount = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.PINBALL_EXTRABARRIER_COUNTER);
        this.addChild(amount);
        amount.setPositionRound(this.width / 2, this.height / 2);
        this.updateStage(extraBarrier.stage);

        this.setPositionRound(PinballGame.getViewPosition(extraBarrier.position));

        extraBarrier.on("changeStage", this.createListener(this.changeStage.bind(this)), this);
        extraBarrier.on("giveReward", this.createListener(this.giveReward.bind(this)), this);
    },

    changeStage: function (stage) {
        this.updateStage(stage);

        if (stage === 0) {
            this.view.setAnimation(0, "idle", true);
            return;
        }

        this.view.setAnimationAndIdleAfter("open_" + stage, "idle_" + stage);
    },

    updateStage: function (stage) {
        var stagesLeft = this.extraBarrier.getStagesLeft(stage);
        this.amount.setString(stagesLeft === 0 ? "" : stagesLeft);
    },

    giveReward: function (reward) {
        this.parent.giveReward(this, reward);
    }
});

cleverapps.styles.FONTS.PINBALL_EXTRABARRIER_COUNTER = {
    size: 50,
    color: cleverapps.styles.COLORS.WHITE,
    stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
};