/**
 * Created by Ivan on 23.08.2024
 */

var PinballGameView = cc.Node.extend({
    ctor: function (mission, fieldSize) {
        this._super();
        this.mission = mission;
        this.pinball = mission.game;

        this.fieldSize = fieldSize;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(this.fieldSize);

        this.createBall();
        this.createBarriers();
        this.createPrizes();

        this.pinball.setUpdateCallback(this.updateScheduler.bind(this));
        this.pinball.on("prize_highlight", this.runPrizeHighlight.bind(this));
        this.pinball.on("stop", this.clearPrizeHighlight.bind(this));

        cleverapps.UI.onClick(this, this.onClicked.bind(this), {
            interactiveScale: false
        });
    },

    onClicked: function (touch) {
        if (this.mission.isGameAvailable()) {
            this.startGame(touch);
            cleverapps.audio.playSound(bundles.pinball.urls.pinball_round_start_effect);
        }
    },

    startGame: function (touch) {
        cleverapps.scenes.getRunningScene().onStartGame();

        var pos = this.convertTouchToNodeSpace(touch);
        pos.y = this.height - cleverapps.styles.PinballScene.ball.offsetY;
        var absolutePosition = cc.p(pos.x / this.width, pos.y / this.height);
        this.mission.startGame(cc.p(absolutePosition.x * PinballGame.FIELD_WIDTH, absolutePosition.y * PinballGame.FIELD_HEIGHT - 30));
    },

    createBall: function () {
        this.ballView = new BallView(this.pinball.ball);
        this.addChild(this.ballView, 1);
    },

    createBarriers: function () {
        var barriers = this.pinball.virtualBarriers.concat(this.pinball.barriers);
        barriers.forEach(function (barrier) {
            var pos = PinballGame.getViewPosition(barrier.position);
            var type = barrier.type;

            if (type === "extra") {
                this.addChild(new ExtraBarrierView(barrier, pos));
            } else if (!barrier.hidden) {
                var view;
                if (type) {
                    view = new cc.Sprite(PinballScene.BARRIER_IMAGE[type]);
                } else {
                    view = new cleverapps.Spine(PinballScene.BARRIER_IMAGE.default);
                    view.setAnimation(0, "animation");
                }
                this.addChild(view, 3);
                barrier.updateRadius && barrier.updateRadius(Math.max(view.width, view.height) / 2);
                view.setPositionRound(pos);
            }
        }.bind(this));
    },

    createPrizes: function () {
        this.prizes = [];
        this.pinball.prizes.forEach(function (prize) {
            var prizeView = new PrizeView(prize);
            this.addChild(prizeView, 2);
            this.prizes.push(prizeView);
        }.bind(this));
    },

    clearPrizeHighlight: function () {
        this.stopAllActions();
    },

    runPrizeHighlight: function () {
        this.clearPrizeHighlight();

        var actions = [];

        this.prizes.forEach(function (prize) {
            !prize.isMaxPrize() && actions.push(prize.runHighlightAnimation());
        });

        actions = new cc.Sequence(
            new cc.Spawn(actions),
            new cc.DelayTime(1.5)
        );

        this.runAction(new cc.RepeatForever(actions));
    },

    updateScheduler: function (shouldSchedule) {
        if (shouldSchedule) {
            this.scheduleUpdate();
        } else {
            this.unscheduleUpdate();
        }
    },

    update: function (dt) {
        this.pinball.update(dt);
    },

    win: function () {
        this.pinball.stop();
    },

    getBackgroundStyles: function () {
        return {
            bundle: "pinball_bg",
            backgroundId: "background"
        };
    },

    getAudioStyles: function () {
        return {
            res: bundles.pinball.urls.music,
            delay: 1.0
        };
    },

    listBundles: function () {
        return ["pinball", "pinball_bg", "game"];
    },

    giveReward: function (source, reward) {
        var icon = reward.getIcon();
        source.addChild(icon);
        icon.setPositionRound(cleverapps.styles.PinballGameView.rewardIcons);

        icon.runAction(AnimationsLibrary.shift());
        source.runAction(
            new cc.Sequence(
                new cc.DelayTime(0.5),
                new cc.CallFunc(function () {
                    reward.collectRewardsAnimation(icon, {
                        beginScale: 1,
                        flyingScale: 1,
                        flyingAnimation: Reward.COLLECT_ANIMATION
                    });
                })
            )
        );
    }
});

cleverapps.styles.PinballGameView = {
    rewardIcons: {
        x: {
            align: "center"
        },
        y: {
            align: "center"
        }
    }
};