/**
 * Created by Vladislav on 05.09.2024.
 */

StickersBookWindow.prototype.stickerAppearAnimation = function () {
    return new cc.Sequence(
        new cc.ScaleTo(0, 0.6),
        new cc.FadeOut(0),
        new cc.Show(),
        new cc.Spawn(
            new cc.ScaleTo(0.5, 1.5).easing(cc.easeBackOut()),
            new cc.FadeIn(0.5)
        ),
        new cc.DelayTime(1)
    );
};

StickersCompletedWindow.prototype.runDiagonalAnimation = function (params) {
    var puzzlesMatrix = this.layout.rows;
    var row = params.row;
    var col = params.col;

    while (row >= 0 && col < puzzlesMatrix[row].length) {
        var puzzleAction;

        if (params.isReverse) {
            puzzleAction = new cc.Sequence(
                new cc.ScaleTo(0, 0.4),
                new cc.Spawn(
                    new cc.ScaleTo(0.1, 0.8),
                    new cc.FadeIn(0.1)
                )
            );
        } else {
            puzzleAction = new cc.Sequence(
                new cc.Spawn(
                    new cc.ScaleTo(0.1, 0.4),
                    new cc.FadeOut(0.1)
                ),
                new cc.RemoveSelf()
            );
        }

        puzzlesMatrix[row][col].runAction(new cc.Sequence(
            new cc.DelayTime(params.delay + params.diagonalIndex * 0.1),
            puzzleAction
        ));

        row--;
        col++;
    }
};

StickersCompletedWindow.prototype.stickersCompleteAnimation = function (isReverse, animationDelay) {
    var puzzlesMatrix = this.layout.rows;
    var diagonalIndex = 0;

    for (var startRow = 0; startRow < puzzlesMatrix.length; startRow++) {
        this.runDiagonalAnimation({
            row: startRow,
            col: 0,
            diagonalIndex: diagonalIndex,
            isReverse: isReverse,
            delay: animationDelay || 0
        });
        diagonalIndex++;
    }

    for (var startCol = 1; startCol < puzzlesMatrix[0].length; startCol++) {
        this.runDiagonalAnimation({
            row: puzzlesMatrix.length - 1,
            col: startCol,
            diagonalIndex: diagonalIndex,
            isReverse: isReverse,
            delay: animationDelay || 0
        });
        diagonalIndex++;
    }

    this.runAction(new cc.Sequence(
        new cc.DelayTime(0.5),
        new cc.CallFunc(function (isReverse) {
            if (!isReverse) {
                return;
            }

            var animation = new cleverapps.Spine(bundles.stickers_completed_window.jsons.puzzle_light_win_json);
            this.content.addChild(animation);
            animation.setScale(0.8);
            animation.setPositionRound(this.layout.getPosition());
            animation.setAnimation(0, "animation", false);
        }.bind(this, isReverse))
    ));
};

StickersCompletedWindow.prototype.createCollection = function () {
    var styles = cleverapps.styles.StickersCompletedWindow;

    cleverapps.stickersBook.collections[this.collectionIndex].stickers.forEach(function (sticker) {
        var ViewClass = sticker.getViewClass();
        var stickerView = new ViewClass(sticker, {
            noTape: true
        });
        stickerView.setScale(0.8);
        stickerView.setCascadeOpacityEnabledRecursively(true);
        stickerView.setOpacity(0);
        runCleaners(stickerView);
        this.stickers.push(stickerView);
    }.bind(this));

    var layout = this.layout = new cleverapps.GridLayout(this.stickers, {
        columns: 3
    });
    this.content.addChild(layout);
    layout.setPositionRound(styles.puzzles);
};