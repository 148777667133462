/**
 * Created by Ivan on 01.08.2024
 */

var PrizeView = cc.Node.extend({
    ctor: function (prize) {
        this._super();
        this.prize = prize;
        var prizeValue = this.prizeValue = Pinball.REWARDS[prize.index];
        var styles = cleverapps.styles.PinballPrizeView;

        this.setAnchorPoint(0.5, 0.5);
        var text = cleverapps.UI.generateOnlyText("x" + prizeValue, cleverapps.styles.FONTS.PINBALL_PRIZE_TEXT);
        this.addChild(text);
        cleverapps.UI.wrap(this);
        text.setPositionRound(styles.text);
        this.idleAnimation = this.isMaxPrize() && this.createIdleAnimation(true);
        this.setPositionRound(PinballGame.getViewPosition(prize.position));
        prize.on("giveReward", this.createListener(this.giveReward.bind(this)), this);
    },

    createIdleAnimation: function (repeat) {
        var styles = cleverapps.styles.PinballPrizeView;
        var animationIdle = new cleverapps.Spine(
            this.isMaxPrize() ? bundles.pinball.jsons.prize_jackpot_idle_json : bundles.pinball.jsons.prize_idle_json
        );
        this.addChild(animationIdle);
        animationIdle.setPositionRound(styles.idle);
        animationIdle.setAnimation(0, "animation", repeat);
        return animationIdle;
    },

    isMaxPrize: function () {
        return this.prizeValue === Pinball.MAX_REWARD;
    },

    runHighlightAnimation: function () {
        return !this.isMaxPrize() && new cc.Sequence(
            new cc.DelayTime(
                Math.min(this.prize.index, Pinball.REWARDS.length - 1 - this.prize.index) * 0.3
            ),
            new cc.CallFunc(function () {
                var animation = this.createIdleAnimation();
                animation.setCompleteListenerRemove();
            }.bind(this))
        );
    },

    createPrizeTriggerAnimation: function () {
        var animation = new cleverapps.Spine(bundles.pinball.jsons.prize_explosion_json);
        this.addChild(animation);
        animation.setPositionRound(cleverapps.styles.PinballPrizeView.prize);
        animation.setAnimation(0, "animation", false);
        cleverapps.audio.playSound(bundles.pinball.urls["prize_" + this.prizeValue + "_effect"]);
        animation.setCompleteListenerRemove(function () {
            if (this.isMaxPrize()) {
                this.idleAnimation = this.createIdleAnimation(true);
            }
        }.bind(this));
        return animation;
    },

    giveReward: function (reward) {
        this.idleAnimation && this.idleAnimation.removeFromParent();
        this.idleAnimation = undefined;
        this.createPrizeTriggerAnimation();
        this.parent.giveReward(this, reward);
    }
});
cleverapps.overrideColors(cleverapps.styles.COLORS, {
    PINBALL_PRIZE_COLOR: new cc.Color(180, 87, 1, 255)
});
cleverapps.styles.FONTS.PINBALL_PRIZE_TEXT = {
    size: 40,
    name: "nostroke",
    color: cleverapps.styles.COLORS.PINBALL_PRIZE_COLOR,
    shadow: cleverapps.styles.DECORATORS.LIGHT_WHITE_SHADOW
};

cleverapps.styles.PinballPrizeView = {
    prize: {
        x: { align: "center" },
        y: { align: "center", dy: 0 }
    },
    idle: {
        x: { align: "center", dx: 4 },
        y: { align: "center", dy: -28 }
    },
    text: {
        x: { align: "center" },
        y: { align: "bottom", dy: -65 }
    }
};
