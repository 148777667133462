/**
 * Created by Ivan on 15.11.2024
 */

var PinballOffer = function (scene) {
    var windowOffer = this.windowOffer = new WindowOffer(scene, {
        text: "Pinball.Offer",
        onClicked: this.onClicked.bind(this),
        offerType: Offers.TYPES.PINBALL_PACK,
        bundle: "pinball",
        badgeText: "SALE"
    });

    windowOffer.packView.show = PinballOffer.showPack.bind(windowOffer.packView);
    windowOffer.packView.hide = PinballOffer.hidePack.bind(windowOffer.packView);
};

PinballOffer.prototype.getOffer = function () {
    return this.windowOffer;
};

PinballOffer.prototype.onClicked = function () {
    cleverapps.focusManager.display({
        focus: "PinballOffer",
        actions: [
            function (f) {
                this.windowOffer.hide();
                f();
            }.bind(this),
            function (f) {
                new MissionOfferWindow(cleverapps.offerManager.findOffer({ type: Offers.TYPES.PINBALL_PACK }));
                cleverapps.focusManager.onceNoWindowsListener = f;
            },
            function (f) {
                this.windowOffer.show();
                f();
            }.bind(this)]
    });
};

PinballOffer.showPack = function () {
    if (this.shown) {
        return;
    }

    var position = this.getPosition();
    this.setPosition(position.x, -this.height);

    this.shown = true;

    this.stopAllActions();
    this.runAction(new cc.Sequence(
        new cc.Show(),
        new cc.MoveTo(0.15, position.x, position.y).easing(cc.easeBackOut(2))
    ));
};

PinballOffer.hidePack = function () {
    if (!this.shown) {
        return;
    }

    var position = this.getPosition();
    this.shown = false;

    this.stopAllActions();
    this.runAction(new cc.Sequence(
        new cc.MoveTo(0.15, position.x, -this.height).easing(cc.easeBackIn(2)),
        new cc.Hide(),
        new cc.CallFunc(function () {
            this.setPositionRound(position);
        }.bind(this))
    ));
};
