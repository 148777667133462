/**
 * Created by Ivan on 01.08.2024
 */

var Ball = function (radius) {
    Body.call(this, undefined, radius);
    this.direction = cc.p(0, 0);
};

Ball.prototype = Object.create(Body.prototype);
Ball.prototype.constructor = Ball;

Ball.prototype.calcDirection = function (angle, length) {
    this.direction = cc.p(Math.cos(angle) * length, Math.sin(angle) * length);
};

Ball.prototype.slowDown = function (rate) {
    this.direction = cc.pMult(this.direction, rate || 1);
};

Ball.prototype.updatePosition = function (dt, gravity, fieldSize) {
    this.direction = cc.pAdd(this.direction, cc.pMult(gravity, dt));
    var position = cc.pAdd(this.position, cc.pMult(this.direction, dt));
    var radius = this.radius;

    if (position.y - radius <= 0) {
        this.stopped = true;
        return;
    }

    if (position.x - radius <= 0 || position.x + radius >= fieldSize.width) {
        position.x = position.x - radius <= 0 ? radius : fieldSize.width - radius;
        this.direction.x *= -1;
        this.slowDown(0.9);
        cleverapps.audio.playSound(bundles.pinball.urls.walls_effect);
    }

    if (position.y + radius >= fieldSize.height) {
        position.y = position.y - radius <= 0 ? position.y : fieldSize.height - radius;
        this.direction.y *= -1;
        this.slowDown(0.9);
        cleverapps.audio.playSound(bundles.pinball.urls.walls_effect);
    }

    this.position = position;
    this.trigger("updatePosition");
};

Ball.prototype.isWin = function () {
    return this.stopped;
};

Ball.prototype.show = function () {
    this.stopped = false;
    this.trigger("show");
};

Ball.prototype.hide = function () {
    this.trigger("hide");
};
